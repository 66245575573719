import type { IndividualCustomerSinglePackagePrice } from './useCartProductData/useCartProductData.types'

export function isNetPriceEqualNetPriceWithDiscount(individualCustomerSinglePackagePrice: IndividualCustomerSinglePackagePrice, netPrice: number, netPriceWithDiscount: number) {
  if (
    !individualCustomerSinglePackagePrice
    || typeof individualCustomerSinglePackagePrice !== 'number'
    || !netPrice
    || !netPriceWithDiscount
  ) {
    return false
  }

  return (
    netPrice === netPriceWithDiscount
    && netPrice === individualCustomerSinglePackagePrice
  )
}

/**
 * Method used for calculating not available quantity of products on cart
 * @param {number} quantityOnCartItem - taken from product's direct quantity
 * @param {number} availableQuantity - taken fron availability_data/availabilityData on cart
 */
export function calculateNotAvailableQuantity(quantityOnCartItem: number, availableQuantity: number) {
  if (
    !quantityOnCartItem
    || !availableQuantity
    || typeof quantityOnCartItem !== 'number'
    || typeof availableQuantity !== 'number'
  ) {
    return
  }

  return Math.abs(quantityOnCartItem - availableQuantity)
}

export function isRowQuantityGreaterThanAvailableQuantity(quantityInCart: number, availableQuantity: number) {
  return (
    typeof availableQuantity === 'number' && quantityInCart > availableQuantity
  )
}

export function isAvailableQuantityExceeded(quantityOnCartItem: number, availableQuantity: number) {
  return (
    isRowQuantityGreaterThanAvailableQuantity(
      quantityOnCartItem,
      availableQuantity,
    ) && availableQuantity > 0
  )
}
export function hasProductExtendedDeliveryTime(availableQuantity: number) {
  return typeof availableQuantity === 'number' && availableQuantity < 1
}
